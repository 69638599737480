<template>
    <div>
        <Index class="ads-bottom" />
        <div class="bottom-ad bottomLink mobile-fixed-theme" >
            <span class="bottom-tip">
                คุณสามารถส่งสินค้าต่าง ๆ ที่คุณต้องการให้ @BeeRicher และระบบจะตอบกลับมาโดยแสดงราคาส่ง
            </span>
            <div class="bottom-button">
                <a href="https://lin.ee/3wbxKDaz"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"></a>
            </div>
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
import Index from "../home/index/Index.vue";
import { openWebPageBlank } from "@/utils/pages";
import { Image as VanImage } from 'vant';

export default {
    components: {
        Index,
        [VanImage.name]: VanImage,
    },
    data() {
        return {
        };
    },
    methods: {
        toLinMe() {
            openWebPageBlank('https://lin.ee/3wbxKDaz')
        }
    }
};
</script>
<style lang="less" scoped>
.bottomLink {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    bottom: 10px;
    left: 20px;
    right: 20px;
    cursor: pointer;
    background: linear-gradient(-180deg,#f53d2d,#f63);
    border-radius: 10px;
}
.bottom-button {
    margin-top: 5px;
}

.bottom-ad {
    width: 300px;
    padding: 10px;
}

.bottom-tip {
    display: block;
    color: #fff;
    padding-right: 20px;
    font-size: 1.2rem;
}

.ads-bottom {
    padding-bottom: 125px;
}

@media (max-width: 500px) {
    .ads-bottom {
        padding-bottom: calc((100vw-40px)/4);
    }
}
</style>
  